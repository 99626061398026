import { AspectRatio, Box, Icon } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import Button from '@components/Button';
import { Photo } from '@components/PhotoGallery/types';
import { SEARCH_PARAM } from '@constants/search-params';
import { handleShare } from '@utils/share';
import WhichImage from '@components/Image/WhichImage';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { MdClose, MdIosShare } from 'react-icons/md';
import { IconProps } from '@chakra-ui/icons';
import { useSwipe } from '@hooks/useSwipe';

interface PhotoGalleryModalSliderProps {
  images: Photo[];
  galleryIndex: number;
  title?: string;
  setGalleryIndex: React.Dispatch<React.SetStateAction<null | number>>;
  onCloseModal: () => void;
}
const ButtonIconProps: IconProps = {
  color: 'gray.300',
  _hover: { color: 'white' },
  cursor: 'pointer',
  position: 'absolute',
  transform: 'translateY(-50%)',
  top: '50%',
  borderRadius: '100%',
  padding: 0,
  fontSize: { base: '30px', '2xl': '24px' },
};

const PhotoGalleryModalSlider: React.FC<PhotoGalleryModalSliderProps> = ({
  images,
  galleryIndex,
  setGalleryIndex,
  onCloseModal,
}) => {
  const toast = useToast();
  const shareUrl = typeof window !== 'undefined' ? window.location.href : '';

  const nextSlide = () => {
    const newPhotoIndex = galleryIndex === images.length ? 1 : galleryIndex + 1;
    setGalleryIndex(newPhotoIndex);
    window.history.replaceState(
      window.history.state,
      '',
      `?${SEARCH_PARAM.PHOTO_INDEX}=${newPhotoIndex}`
    );
  };

  const prevSlide = () => {
    const newPhotoIndex = galleryIndex === 1 ? images.length : galleryIndex - 1;
    setGalleryIndex(newPhotoIndex);
    window.history.replaceState(
      window.history.state,
      '',
      `?${SEARCH_PARAM.PHOTO_INDEX}=${newPhotoIndex}`
    );
  };

  const { handleTouchStart, handleTouchEnd } = useSwipe({
    onSwipeLeft: prevSlide,
    onSwipeRight: nextSlide,
  });

  const photoIndex = galleryIndex - 1;
  return (
    <Box
      position="relative"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <AspectRatio maxW="100%" ratio={6 / 4} key={images[photoIndex].url}>
        <WhichImage
          path={images[photoIndex].url}
          title={images[photoIndex].alt}
          width={600}
          height={400}
          style={{
            objectFit: 'cover',
          }}
        />
        {/* <Image
          data-attribbyme='yes'
          src={images[photoIndex].url}
          alt={images[photoIndex].alt}
          width="600"
          height="400"
          style={{
            objectFit: 'cover',
          }}
        /> */}
      </AspectRatio>

      <Box
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
      >
        {images.map((_, index) => (
          <Box
            as="span"
            key={index}
            mx="1"
            h=".5rem"
            w=".5rem"
            borderRadius="full"
            bg={index === photoIndex ? 'white' : 'gray.400'}
            display="inline-block"
          />
        ))}
      </Box>
      <Icon
        {...ButtonIconProps}
        left={{ base: 1, '2xl': '-28px' }}
        as={IoIosArrowDropleftCircle}
        onClick={prevSlide}
      />
      <Icon
        {...ButtonIconProps}
        right={{ base: 1, '2xl': '-28px' }}
        as={IoIosArrowDroprightCircle}
        onClick={nextSlide}
      />
      <Box position="absolute" display="flex" gap="2" top="4" pl="4">
        <Button
          colorScheme="gray"
          title="Share photo"
          elementAction={ElementActions.CLOSE_MODAL}
          elementName={ElementNames.VIEW_GALLERY}
          elementType={ElementTypes.BUTTON}
          onClick={onCloseModal}
        >
          <Icon as={MdClose} />
        </Button>
        <Button
          colorScheme="primary"
          title="Share photo"
          elementAction={ElementActions.SHARE}
          elementName={ElementNames.VIEW_GALLERY}
          elementType={ElementTypes.BUTTON}
          onClick={() => handleShare(shareUrl, toast)}
        >
          <Icon as={MdIosShare} />
        </Button>
      </Box>
    </Box>
  );
};

export default PhotoGalleryModalSlider;
